/* src/global.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background-color: #000000; /* Match the background color of your app */
    color: white; /* Ensure text is visible on dark background */
    font-family: 'Roboto', sans-serif; /* Use your preferred font */
  }